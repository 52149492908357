import React, { useEffect, useState, useContext } from 'react';
import { GroupuiCard, GroupuiStepperHorizontal, GroupuiStep, GroupuiDivider, GroupuiLoadingSpinner } from '@group-ui/group-ui-react';
import axios from 'axios';
import '@group-ui/group-ui-react/node_modules/@group-ui/group-ui/dist/group-ui/assets/themes/tokens.css'
import { applyPolyfills, defineCustomElements } from '@group-ui/group-ui-react/node_modules/@group-ui/group-ui/dist/loader';
import './App.css';
import ComposeNewMessage from './components/ComposeNewMessage';
import ReviewMessage from './components/ReviewMessage';
import CheckLogOutput from './components/CheckLogOutput';
import { multiStepContext } from './StepContext';
import { useCookies } from 'react-cookie'
import { sendAuthorizationRequest, sendTokenRequest, sendRefreshTokenRequest } from './components/tokenService';
import { CONFIG } from "./config";

applyPolyfills().then(() => {
  defineCustomElements();
});


function App() {
  const [Templates, setTemplates] = useState([]);
  const [Recipients, setRecipients] = useState([]);
  const [RecipientsChecked, setRecipientsChecked] = useState({});
  const [Subject, setSubject] = useState()
  const [Message, setMessage] = useState()
  const [Severity, setSeverity] = useState()
  const [isLoaded, setIsLoaded] = useState(false)
  const [LogResponse, setLogResponse] = useState()

  const { currentStep, setStep } = useContext(multiStepContext)

  const [code, setCode] = useState("");
  const [tokenResponse, setTokenResponse] = useState({});
  const [cookies, setCookie] = useCookies(false)

  useEffect(() => {
    const fetchAccessToken = async () => {
      const code = new URL(window.location.href).searchParams.get("code");
      if (code && cookies.ACCESS_TOKEN === undefined) {
        if (cookies.REFRESH_TOKEN === undefined) {
          //when no refresh_token cookie is set
          setCode(code);
          sendTokenRequest(code)
            .then(response => {
              let currentDate = new Date()
              let expiresAccessToken = new Date(currentDate.getTime() + response[0].expires_in * 1000)
              let expiresRefreshToken = new Date(currentDate.getTime() + response[0].refresh_expires_in * 1000)

              setCookie('ACCESS_TOKEN', response[0].access_token, { path: '/', expires: expiresAccessToken })
              setCookie('REFRESH_TOKEN', response[0].refresh_token, { path: '/', expires: expiresRefreshToken })
              runAPIRequests(response[0].access_token)
            })
            .catch((error => {
              sendAuthorizationRequest()
            }));
        } else {
          //when no access_token cookie is set
          sendRefreshTokenRequest(cookies.REFRESH_TOKEN).then(response => {
            let currentDate = new Date()
            let expiresAccessToken = new Date(currentDate.getTime() + response[0].expires_in * 1000)
            let expiresRefreshToken = new Date(currentDate.getTime() + response[0].refresh_expires_in * 1000)

            setCookie('ACCESS_TOKEN', response[0].access_token, { path: '/', expires: expiresAccessToken })
            setCookie('REFRESH_TOKEN', response[0].refresh_token, { path: '/', expires: expiresRefreshToken })
            runAPIRequests(response[0].access_token)
          })
        }
      } else {
        //when access_token and refresh_token cookies are set
        runAPIRequests(cookies.ACCESS_TOKEN)
      }
    };

    const runAPIRequests = async (access_token) => {
      await fetchTemplates(access_token)
      await fetchRecipients(access_token)
      setIsLoaded(true)
    }

    const fetchTemplates = async (access_token) => {
      try {
        const response = await axios.get(`${CONFIG.APPLICATION_URL}api/gettemplates`, {
          headers: {
            'Token': access_token
          },
        });
        response.data.Items.sort((a, b) => (a.template_name.S.toUpperCase() > b.template_name.S.toUpperCase()) ? 1 : -1)
        setTemplates(response.data.Items);
      } catch (error) {
        const code = new URL(window.location.href).searchParams.get("code");
        if (error.response && (error.response.status === 401 || error.response.status === 403)) {
          if (cookies.ACCESS_TOKEN === undefined && cookies.REFRESH_TOKEN !== undefined) {
            const newAccessToken = await sendRefreshTokenRequest(cookies.REFRESH_TOKEN).then(response => {
              let currentDate = new Date()
              let expiresAccessToken = new Date(currentDate.getTime() + response[0].expires_in * 1000)
              let expiresRefreshToken = new Date(currentDate.getTime() + response[0].refresh_expires_in * 1000)

              setCookie('ACCESS_TOKEN', response[0].access_token, { path: '/', expires: expiresAccessToken })
              setCookie('REFRESH_TOKEN', response[0].refresh_token, { path: '/', expires: expiresRefreshToken })
              return response[0].access_token
            })
            const response = await axios.get(`${CONFIG.APPLICATION_URL}api/gettemplates`, {
              headers: {
                'Token': newAccessToken
              },
            });
            response.data.Items.sort((a, b) => (a.template_name.S.toUpperCase() > b.template_name.toUpperCase()) ? 1 : -1)
            setTemplates(response.data.Items);
          } else if (cookies.ACCESS_TOKEN === undefined && cookies.REFRESH_TOKEN === undefined) {
            sendAuthorizationRequest();
          }
        }
      };
    }

    const fetchRecipients = async (access_token) => {
      try {
        const response = await axios.get(`${CONFIG.APPLICATION_URL}api/getrecipients`, {
          headers: {
            'Token': access_token,
          },
        });

        response.data.Items.sort((a, b) => (a.recipient_name.S.toUpperCase() > b.recipient_name.S.toUpperCase()) ? 1 : -1)
        setRecipients(response.data.Items);

      } catch (error) {
        const code = new URL(window.location.href).searchParams.get("code");
        if (error.response && (error.response.status === 401 || error.response.status === 403)) {
          if (cookies.ACCESS_TOKEN === undefined && cookies.REFRESH_TOKEN !== undefined) {
            const newAccessToken = await sendRefreshTokenRequest(cookies.REFRESH_TOKEN).then(response => {
              let currentDate = new Date()
              let expiresAccessToken = new Date(currentDate.getTime() + response[0].expires_in * 1000)
              let expiresRefreshToken = new Date(currentDate.getTime() + response[0].refresh_expires_in * 1000)

              setCookie('ACCESS_TOKEN', response[0].access_token, { path: '/', expires: expiresAccessToken })
              setCookie('REFRESH_TOKEN', response[0].refresh_token, { path: '/', expires: expiresRefreshToken })
              return response[0].access_token
            })
            const response = await axios.get(`${CONFIG.APPLICATION_URL}api/getrecipients`, {
              headers: {
                'Token': newAccessToken,
              },
            });
            response.data.Items.sort((a, b) => (a.recipient_name.S.toUpperCase() > b.recipient_name.S.toUpperCase()) ? 1 : -1)
            setRecipients(response.data.Items);
          } else if (cookies.ACCESS_TOKEN === undefined && cookies.REFRESH_TOKEN === undefined) {
            sendAuthorizationRequest();
          }
        }
      }
    };

    function checkAuthorization() {
      const code = new URL(window.location.href).searchParams.get("code");
      if (code === null && cookies.ACCESS_TOKEN === undefined) {
        sendAuthorizationRequest()
      }
    }
    checkAuthorization();
    fetchAccessToken();

  }, []);

  const fetchOneTemplate = async (template_name) => {
    try {
      const response_suggestion = await axios.get(`${CONFIG.APPLICATION_URL}api/gettemplate/`, {
        headers: {
          'Token': cookies.ACCESS_TOKEN,
        },
        params: {
          'template_name': template_name,
        },
      });

      return response_suggestion.data.Items
    } catch (error) {
      const code = new URL(window.location.href).searchParams.get("code");
      if (error.response && (error.response.status === 401 || error.response.status === 403)) {
        if (cookies.ACCESS_TOKEN === undefined && cookies.REFRESH_TOKEN !== undefined) {
          const newAccessToken = await sendRefreshTokenRequest(cookies.REFRESH_TOKEN).then(response => {
            let currentDate = new Date()
            let expiresAccessToken = new Date(currentDate.getTime() + response[0].expires_in * 1000)
            let expiresRefreshToken = new Date(currentDate.getTime() + response[0].refresh_expires_in * 1000)

            setCookie('ACCESS_TOKEN', response[0].access_token, { path: '/', expires: expiresAccessToken })
            setCookie('REFRESH_TOKEN', response[0].refresh_token, { path: '/', expires: expiresRefreshToken })
            return response[0].access_token
          })
          const response_suggestion = await axios.get(`${CONFIG.APPLICATION_URL}api/gettemplate/`, {
            headers: {
              'Token': newAccessToken,
            },
            params: {
              'template_name': template_name,
            },
          });
          return response_suggestion.data.Items
        } else if (cookies.ACCESS_TOKEN === undefined && cookies.REFRESH_TOKEN === undefined) {
          sendAuthorizationRequest();
        }
      }
      console.error('Error fetching data:', error);
    }
  }

  const SuggestionSelected = async (event) => {
    const result = await fetchOneTemplate(event.detail.content)

    setSubject(result[0]['subject']['S'])
    setMessage(result[0]['message']['S'])
    setSeverity(result[0]['severity']['S'])

    setRecipientsChecked(JSON.parse(result[0]['selectedRecipients']['S']))
    document.activeElement.blur();
  }

  function showStep(step) {
    switch (step) {
      case 1:
        return <ComposeNewMessage
          Templates={Templates}
          Recipients={Recipients}
          RecipientsChecked={RecipientsChecked}
          setRecipientsChecked={setRecipientsChecked}
          Severity={Severity}
          setSeverity={setSeverity}
          Subject={Subject}
          setSubject={setSubject}
          Message={Message}
          setMessage={setMessage}
          SuggestionSelected={SuggestionSelected}
        />
      case 2:
        return <ReviewMessage
          Recipients={Recipients}
          RecipientsChecked={RecipientsChecked}
          Severity={Severity}
          Subject={Subject}
          Message={Message}
          setLogResponse={setLogResponse}
        />
      case 3:
        return <CheckLogOutput
          LogResponse={LogResponse}
        />
      default: //
    }
  }

  return (
    <div className="template-forms-others-service container-border light-grey-background flex-center" >
      <div id="theme-switch-wrapper-108">
        <div id="theme-switch-container-108" >
          <GroupuiCard padding="40px" elevation={8} >
            <GroupuiStepperHorizontal>
              <GroupuiStep onClick={() => setStep(1)} active={currentStep === 1 && true} done={currentStep === 2 || currentStep === 3} icon="edit-24">New Message</GroupuiStep>
              <GroupuiStep onClick={() => setStep(2)} active={currentStep === 2 && true} done={currentStep === 3 && true} icon="check-24">Review</GroupuiStep>
              <GroupuiStep active={currentStep === 3 && true} icon="gears-24">Message Status</GroupuiStep>
            </GroupuiStepperHorizontal>
            <br></br>
            <GroupuiDivider></GroupuiDivider>
            <GroupuiLoadingSpinner displayed={!isLoaded}></GroupuiLoadingSpinner>
            {showStep(currentStep)}
            <br></br>
          </GroupuiCard>
        </div >
      </div >

    </div >
  );
};


export default App;